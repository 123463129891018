import { kebabCase } from 'lodash'

export const encodeS3Filename = (filename: string): string => {
  filename = filename.replaceAll(' ', '-')
  // if filename has a dot in it
  const tokens = filename.split('.')
  const n = tokens.length
  const ext = tokens[n - 1],
    rest = tokens.slice(0, n - 1).join('.')

  const name = kebabCase(rest.replaceAll(/[^0-9a-zA-z -]/g, ''))
  return `${name}.${ext}`
}
