import apiClient from './base'

export interface IJobTitlesParams {
  limit?: number
  query?: string
  method?: 'autocomplete' | 'semantic'
}
export const getJobTitles = (params: IJobTitlesParams = {}) => {
  const queryParams = new URLSearchParams()
  Object.keys(params).forEach((key: keyof IJobTitlesParams) => {
    const queryValue = params[key]?.toString()
    queryParams.append(key, queryValue?.toString())
  })
  return apiClient
    .get(`/job-titles/search?${queryParams.toString()}`)
    .then(resp => resp.data)
}

export interface ExistingJobTitlesParams {
  limit: number
  query: string
}
export const getExistingJobTitles = (
  params: ExistingJobTitlesParams
): Promise<string[]> => {
  const queryParams = new URLSearchParams()
  Object.keys(params).forEach((key: keyof ExistingJobTitlesParams) => {
    const queryValue = params[key].toString()
    queryParams.append(key, queryValue.toString())
  })
  return apiClient
    .get(`/jobs/search?${queryParams.toString()}`)
    .then(resp => resp.data)
}
