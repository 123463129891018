import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { FC, ReactNode } from 'react'
import { ShouldRender } from '../misc'

interface Props {
  description?: string
  image?: ReactNode
  title?: string | ReactNode
  descriptionClass?: string
  titleClass?: string
  buttons?: ReactNode | null
  disclaimerTitle?: string
  disclaimerDescription?: string
}

export const EmptyState: FC<Props> = ({
  description = '',
  image = null,
  title = null,
  descriptionClass = 'text-gray-500',
  titleClass = 'font-bold text-base text-gray-800',
  buttons,
  disclaimerDescription,
  disclaimerTitle
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-full gap-2 md:w-3/4 md:mx-auto">
      <ShouldRender check={image}>{image}</ShouldRender>
      <ShouldRender check={disclaimerDescription && disclaimerTitle}>
        <div className="flex flex-col items-center justify-center p-4 rounded-lg bg-yellow-50">
          <p className={clsx(titleClass, 'text-yellow-800')}>
            {disclaimerTitle}
          </p>
          <p className={clsx(descriptionClass, 'text-yellow-800')}>
            {disclaimerDescription}
          </p>
        </div>
      </ShouldRender>
      <ShouldRender check={!isEmpty(title)}>
        <p className={titleClass}>{title}</p>
      </ShouldRender>
      <ShouldRender check={!isEmpty(description)}>
        <p className={descriptionClass}>{description}</p>
      </ShouldRender>
      <ShouldRender check={buttons}>
        <div>{buttons}</div>
      </ShouldRender>
    </div>
  )
}
