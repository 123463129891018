import apiClient from '../base'
import {
  Company,
  CompaniesResponse,
  CompaniesSearchParams,
  UpdateCompanyPayload,
  UpdateCompanyV2Payload
} from 'src/types/shared'
import { getQueryString } from '@/utils/static-helpers/query-params'

export const getAllUserCompanies = () => {
  return apiClient.get('/company').then(res => res.data)
}

//get company details
/**
 * @deprecated This function is deprecated. Use getCompanyDetails instead.
 */
export const getUserCompanyDetails = (companyId: string): any => {
  return apiClient.get(`/company/${companyId}`).then(resp => resp.data)
}

export const getCompanyDetails = (
  companyId: string,
  v2?: boolean
): Promise<Company> => {
  const url = v2 ? `/companies/${companyId}` : `/company/${companyId}`
  return apiClient.get(url).then(resp => resp.data)
}

export const getCompanyByHandle = (handle: string): Promise<Company> => {
  return apiClient.get(`/company/handle/${handle}`).then(resp => resp.data)
}

// Update company profileDetails
export const updateUserCompanyProfileDetails = (
  companyId: string,
  data
): any => {
  return apiClient.patch(`/company/${companyId}`, data).then(resp => resp.data)
}

export const followCompany = (userId, companyId) => {
  return apiClient
    .post(`/company-followers/create/${userId}/${companyId}`)
    .then(resp => resp.data)
}

export const getCompanyJobs = async (
  companyId: string,
  params?: {
    offset?: number
    limit?: number
  }
) =>
  apiClient
    .get(`/company/${companyId}/jobs?${getQueryString(params)}`)
    .then(res => res.data)

export const getChildrenCompanies = async companyId =>
  apiClient.get(`/company/${companyId}/children`).then(res => res.data)

export const createDummyCompany = data =>
  apiClient.post('/company/dummy', data).then(res => res.data)

/**
 * Get Dummy Companies
 *
 */
export const getDummyCompanies = (): any => {
  return apiClient.get(`/company/dummy`).then(resp => resp)
}

export const deleteCompany = companyId =>
  apiClient.delete(`/company/${companyId}`).then(res => res.data)

export const getAllCompanies = async (
  offset = 0,
  limit = 10,
  params: CompaniesSearchParams
) => {
  const queryString = getQueryString({
    ...params,
    offset,
    limit,
    ignoreDummy: true
  })
  const response = await apiClient.get<CompaniesResponse>(
    `/company?${queryString}`
  )
  return response.data
}

/**
 * @deprecated This function is deprecated. Use updateCompanyV2 instead. Please note that BE should also start accepting the other payload.
 */
export const updateCompany = async (
  companyId: string,
  payload: UpdateCompanyPayload
) => {
  const response = await apiClient.patch<
    CompaniesResponse['companies'][number]
  >(`/company/${companyId}`, payload)
  return response.data
}

export const updateCompanyV2 = async (data: {
  companyId: string
  payload: UpdateCompanyV2Payload
}): Promise<Company> => {
  return await apiClient
    .patch<Company>(`/companies/${data.companyId}`, data.payload)
    .then(response => response.data)
}

export const createChildCompany = async (parentCompanyId: string, payload) => {
  const response = await apiClient.post<Company>(
    `/company/${parentCompanyId}/children`,
    payload
  )
  return response.data
}

/** To get list of companies using query on admin portal */
export const getCompanies = async (offset = 0, limit = 10, params) => {
  const queryString = getQueryString({ ...params, offset, limit })
  const response = await apiClient.get<any>(
    `/company/admin/list?${queryString}`
  )
  return response.data
}

// To login as company
export const authenticateCompany = async (
  companyId: string
): Promise<unknown> => {
  const resp = await apiClient.post('/auth/authenticate/company', {
    companyId
  })
  return resp.data
}

export const getCompanyCards = () => {
  return apiClient.get('/companies/cards').then(res => res.data)
}
