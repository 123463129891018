export function getFileSize(bytes: number): string | number {
  if (bytes === 0 || bytes === undefined) return 0
  if (bytes > 0) {
    return (bytes / (1024 * 1024)).toFixed(2)
  }
}

export function getBytesFileSize(megabytes: number): number {
  if (megabytes === 0 || megabytes === undefined) return 0
  if (megabytes > 0) {
    return megabytes * (1024 * 1024)
  }
}
