import {
  castArray,
  compact,
  find,
  identity,
  isArray,
  isNull,
  isUndefined,
  map,
  merge,
  omitBy,
  pickBy,
  uniq
} from 'lodash'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { getExistingJobTitles } from 'src/services/jobTitles'

import { getPastDate, PastDateEnum } from '@/utils/date-helper'
import { ThemeType } from '@qureos/types'

interface JobFiltersProps {
  themes: ThemeType[]
  locations: string[]
}

const useJobFilters = (initialData: JobFiltersProps) => {
  const router = useRouter()
  const { t: Labels } = useTranslation('common')
  const { query, theme, location, datePosted, easyApply, typeOfWork, company } =
    router.query
  const [defaultJobTitles, setDefaultJobTitles] = useState<string[]>()

  const getThemes = () => {
    const themeArray = compact(typeof theme === 'string' ? [theme] : theme)
    const finalTheme = map(themeArray, id =>
      find(initialData?.themes, { _id: id })
    ) as ThemeType[]
    return finalTheme.filter(theme => theme !== undefined)
  }

  const getJobTitles = () => {
    if (isUndefined(defaultJobTitles)) {
      getExistingJobTitles({
        query: !isUndefined(query) ? decodeURI(query as string) : 'Technology',
        limit: 20
      }).then(data => {
        setDefaultJobTitles(data)
      })
    }
  }

  useEffect(() => {
    getJobTitles()
  }, [])

  const handleFilterChange = (data: {
    location: string
    departments: string[]
    datePosted: string
    easyApply: boolean
    jobTitle: string
  }) => {
    const query = omitBy(
      {
        location: data.location,
        theme: data.departments,
        datePosted: isNull(data.datePosted) ? undefined : data.datePosted,
        easyApply: data.easyApply,
        query: data.jobTitle,
        pageNumber: 1
      },
      v => v === undefined || v === false
    )
    router.replace(
      {
        query
      },
      undefined,
      { shallow: true }
    )
  }

  const DateFormat = 'yyyy-MM-dd'
  const datePostedFilters = [
    {
      label: Labels('PAST_MONTH'),
      value: getPastDate({
        date: new Date(),
        type: PastDateEnum.MONTH,
        value: 1,
        format: DateFormat
      }).toString()
    },
    {
      label: Labels('PAST_WEEK'),
      value: getPastDate({
        date: new Date(),
        type: PastDateEnum.WEEK,
        value: 1,
        format: DateFormat
      }).toString()
    },
    {
      label: Labels('PAST_DAY'),
      value: getPastDate({
        date: new Date(),
        type: PastDateEnum.DAY,
        value: 1,
        format: DateFormat
      }).toString()
    }
  ]

  const jobFilterObj = useMemo(() => {
    const selectedLocation = decodeURI(location as string)
    const selectedDatePosted = decodeURI(datePosted as string)
    const selectedEasyApply = !isUndefined(easyApply)
    const selectedJobTitle = decodeURI(query as string)
    const filterQuery = {
      ...pickBy(
        {
          themeIds: isArray(getThemes())
            ? castArray(map(getThemes(), '_id'))
            : undefined,
          location:
            selectedLocation && selectedLocation !== 'undefined'
              ? selectedLocation
              : undefined,
          'createdAt[gte]':
            selectedDatePosted && selectedDatePosted !== 'undefined'
              ? selectedDatePosted
              : undefined,
          includePublicJobs: !selectedEasyApply,
          title:
            selectedJobTitle && selectedJobTitle !== 'undefined'
              ? selectedJobTitle
              : ''
        },
        identity
      )
    }

    return filterQuery
  }, [
    theme,
    location,
    typeOfWork,
    company,
    datePosted,
    easyApply,
    query,
    theme
  ])

  const data = {
    datePostedFilters,
    themes: initialData?.themes,
    locations: initialData?.locations,
    jobTitle: decodeURI(query as string),
    selectedThemes: getThemes(),
    selectedLocation: decodeURI(location as string),
    selectedDatePosted: decodeURI(datePosted as string),
    selectedEasyApply: !isUndefined(easyApply),
    defaultJobTitles: uniq(
      merge(
        defaultJobTitles,
        query && !isUndefined(query) ? [decodeURI(query as string)] : []
      )
    ),
    handleFilterChange,
    filters: jobFilterObj
  }

  return data
}

export default useJobFilters
