import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

type Props = {
  totalPage: number
  currentPage: number
  className?: string
  isLoading: boolean
  clickEvent: (el: number) => void
}

const Pagination = ({
  totalPage,
  currentPage,
  clickEvent,
  className,
  isLoading
}: Props) => {
  // Function to generate a range of numbers
  const range = (start: number, end: number) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i)

  // Calculate visible pages and handle ellipses
  const calculateVisiblePages = () => {
    const maxVisiblePages = 5
    let startPage = 1
    let endPage = totalPage

    if (totalPage > maxVisiblePages) {
      if (currentPage <= maxVisiblePages - 2) {
        endPage = maxVisiblePages
      } else if (currentPage >= totalPage - (maxVisiblePages - 2)) {
        startPage = totalPage - (maxVisiblePages - 1)
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    return range(startPage, endPage)
  }

  // Generate the array of page buttons
  const visiblePages = calculateVisiblePages()

  // Determine if ellipses are needed
  const needsStartEllipsis = totalPage > 1 && visiblePages[0] > 1
  const needsEndEllipsis =
    totalPage > 1 && visiblePages[visiblePages.length - 1] < totalPage

  return (
    <div
      className={clsx(
        'flex flex-row flex-nowrap border-t p-4 pb-2 justify-center gap-x-2 font-medium',
        className
      )}
    >
      <button
        disabled={currentPage === 1 || isLoading}
        onClick={() => !isLoading && clickEvent(currentPage - 1)}
        className={clsx(
          'block p-1 rounded border bg-white text-black',
          currentPage === 1 || isLoading
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
        )}
      >
        <ChevronLeftIcon className="w-4 h-4" />
      </button>

      {needsStartEllipsis && (
        <>
          <button
            key="1"
            onClick={() => clickEvent(1)}
            className={clsx(
              'block h-min p-1 px-2 rounded border text-xs cursor-pointer',
              currentPage === 1
                ? 'border-qurepsPrimary bg-qureosPrimary text-white'
                : 'border-gray-100 bg-white text-center text-gray-900'
            )}
          >
            1
          </button>
          <span className="mx-1">...</span>
        </>
      )}

      {visiblePages.map(page => (
        <button
          key={page}
          onClick={() => clickEvent(page)}
          className={clsx(
            'block h-min p-1 px-2 rounded border text-xs cursor-pointer',
            currentPage === page
              ? 'border-qureosPrimary bg-qureosPrimary text-white'
              : 'border-gray-100 bg-white text-center text-gray-900'
          )}
        >
          {page}
        </button>
      ))}

      {needsEndEllipsis && (
        <>
          <span className="mx-1">...</span>
          <button
            key={totalPage}
            onClick={() => clickEvent(totalPage)}
            className={clsx(
              'block h-min p-1 px-2 rounded border text-xs cursor-pointer',
              currentPage === totalPage
                ? 'border-qureosPrimary bg-qureosPrimary text-white'
                : 'border-gray-100 bg-white text-center text-gray-900'
            )}
          >
            {totalPage}
          </button>
        </>
      )}

      <button
        disabled={currentPage === totalPage || isLoading}
        onClick={() => !isLoading && clickEvent(currentPage + 1)}
        className={clsx(
          'block p-1 rounded border bg-white text-black',
          currentPage === totalPage || isLoading
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
        )}
      >
        <ChevronRightIcon className="w-4 h-4" />
      </button>
    </div>
  )
}

export default Pagination
