import { JobDetailView } from '@/components/jobs/JobDetailView'
import { EmptyState } from '@/components/shared/EmptyState'
import { Button } from '@/components/shared/basic/Buttons/Button'
import { ShouldRender } from '@/components/shared/misc'
import Pagination from '@/components/shared/misc/PaginationV2'
import {
  encodeS3Filename,
  isLoggedIn,
  loginRedirect,
  notify
} from '@/utils/lib'
import { segmentTrackCallback } from '@/utils/segment'
import {
  getBytesFileSize,
  getFileSize
} from '@/utils/static-helpers/fileSizeConverter'
import { formatErrorObject } from '@/utils/static-helpers/formatError'
import { poweredByQureosDark } from '@/utils/static-helpers/images'
import { InboxOutlined } from '@ant-design/icons'
import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { SourceNoData } from '@qureos/assets'
import { perPageJobsCount } from '@qureos/statics/jobs'
import {
  ApprenticeProfile,
  CV,
  CorporateJobResponse,
  JobType,
  SegmentEventType
} from '@qureos/types'
import { Drawer, Input, Modal, Upload, message } from 'antd'
import clsx from 'clsx'
import { formatDistanceToNow } from 'date-fns'
import { isEmpty, isEqual, isNumber, omitBy } from 'lodash'
import moment from 'moment'
import { nanoid } from 'nanoid'
import Image from 'next/image'
import Router, { useRouter } from 'next/router'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { job as jobAtom, user as userAtom } from 'src/atoms'
import useGcs from 'src/hooks/useGcs'
import { updateApprenticesResume } from 'src/services/apprentices/professionalDetails'
import {
  getAllJobs,
  startApply,
  triggerSubmissionEmail
} from 'src/services/jobs'
import { captureEvent } from 'src/services/user-analytics'
import {
  UserAnalyticsEventName,
  UserAnalyticsEventType
} from 'src/types/userAnalytics'

const { Dragger } = Upload
export interface JobsProps {
  data: CorporateJobResponse[]
  metaData: { total: number }[]
}

interface JobFiltersProps {
  jobId: string
  pageNumber: number
  handlePageNumberChange: (pageNumber: number) => void
  handleJobIdChange: (jobId: string) => void
  selectedJob?: CorporateJobResponse
  setSelectedJob?: (data) => void
  setTotalJobs?: (el: number) => void
  filters?: {
    themeIds?: string[]
    location?: string
    'createdAt[gte]'?: string
    includePublicJobs?: boolean
    title?: string
  }
}

const PublicJobs: React.FC<JobFiltersProps> = ({
  jobId,
  pageNumber,
  handlePageNumberChange,
  handleJobIdChange,
  selectedJob,
  setSelectedJob,
  setTotalJobs,
  filters
}) => {
  const { query } = useRouter()
  const pageRoute = useRouter()
  const { t: Labels } = useTranslation('CorporateJobs')
  const [jobs, setJobs] = useState<JobsProps>(null)
  const [loading, setLoading] = useState(false)
  const [listLoading, setListLoading] = useState(true)
  const [totalPage, setTotalPage] = useState(1)
  const divRef = useRef<HTMLDivElement>()
  const { uploadToGcs } = useGcs()
  const [, setJob] = useRecoilState(jobAtom)
  const [user] = useRecoilState<ApprenticeProfile>(userAtom)
  const [cv, setCv] = useState<string>()
  const [cvLinks, setCvLinks] = useState<CV[]>([])
  const [screeningAnswers, setScreeningAnswers] = useState([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [parseCV, setParseCV] = useState<boolean>(false)
  const [selectedResume, setSelectedResume] = useState('')
  const maxFileSize = getBytesFileSize(5)
  const [draggerKey, setDraggerKey] = useState<string>(nanoid())
  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  const fetchJobs = async () => {
    try {
      const offset = ((pageNumber || 1) - 1) * perPageJobsCount
      setListLoading(true)
      const response = await getAllJobs({
        ignoreAppliedJobs: false,
        limit: perPageJobsCount,
        offset: isNumber(offset) ? offset : 0,
        ...filters
      })
      if (response?.data) {
        setJobs(response)
        let selected = null

        if (response.data.length > 0) {
          setTotalJobs?.(response?.metaData?.[0]?.total)
          if (jobId) {
            selected = response.data.find(job => job._id === jobId)
            if (!selected) selected = response.data[0]
          } else selected = response.data[0]
        }
        setSelectedJob(selected)
        handleJobIdChange(selected?._id)
      } else {
        setSelectedJob(null)
        handleJobIdChange(null)
      }
    } catch (error) {
      const message = formatErrorObject(error)
      notify({ message: message, type: 'error' })
      setJobs(null)
      handleJobIdChange(null)
    } finally {
      setListLoading(false)
    }
  }

  useEffect(() => {
    fetchJobs()
  }, [])

  const prevJobFilterObj = useRef(filters)
  const prevPageNumber = useRef(pageNumber)
  useEffect(() => {
    const themeIds = filters?.themeIds as string[]
    const publicJobs = filters?.includePublicJobs as boolean

    const updatedFilterObj = omitBy(
      {
        ...filters,
        themeIds: themeIds?.length > 0 ? themeIds : undefined,
        includePublicJobs: publicJobs === true ? undefined : publicJobs
      },
      v => v === undefined
    )

    if (
      !isEqual(prevJobFilterObj.current, filters) ||
      isEmpty(updatedFilterObj) ||
      prevPageNumber.current !== pageNumber
    ) {
      setSelectedJob(null)
      if (!listLoading) fetchJobs()
    }
    prevJobFilterObj.current = filters
    prevPageNumber.current = pageNumber
  }, [filters, pageNumber])

  useEffect(() => {
    setScreeningAnswers(
      selectedJob?.screeningQuestions?.map(({ question: text }) => ({
        text,
        answer: ''
      })) || []
    )
  }, [selectedJob?.screeningQuestions])

  useEffect(() => {
    if (selectedJob) {
      const divR = divRef.current
      if (divR) divR.scrollTop = 0
      setJob(selectedJob) // this will update jobs on jobAtom

      const selectedIndex = jobs?.data?.findIndex(
        job => job._id === selectedJob._id
      )
      if (selectedIndex !== -1 && typeof selectedIndex === 'number') {
        setScrollHeight(selectedIndex * 120)
      }
    }
  }, [selectedJob])

  const hasPendingQuestions = useMemo(
    () => screeningAnswers.some(sq => !sq.answer),
    [screeningAnswers]
  )

  const handleJobClick = (data: CorporateJobResponse) => {
    handleJobIdChange(data._id)
    setSelectedJob(data)
    setShowSlider(true)
    resetCvSelection()
  }

  const getLocation = jobData => {
    return jobData.locations?.[0]?.location ?? jobData.location
  }

  const getRemoteLocation = jobData => {
    if (
      jobData.workStyle === JobType.Remote ||
      jobData.workStyles?.includes(JobType.Remote)
    ) {
      const location = getLocation(jobData)
      return Labels('REMOTE') + (location ? ` | ${location}` : '')
    }
    return null
  }

  const getCreatedDuration = jobData => {
    return jobData.createdDateTime
      ? formatDistanceToNow(jobData.createdDateTime, { addSuffix: true })
      : null
  }

  const jobsCard = useMemo(() => {
    if (listLoading) {
      return (
        <div className="min-h-[60vh] flex flex-col items-center justify-center">
          <qc-spinner />
        </div>
      )
    } else {
      const totalData = jobs?.metaData?.[0].total ?? 0
      const pageCount =
        Number(totalData) < perPageJobsCount
          ? 1
          : Math.ceil(Number(totalData) / perPageJobsCount)
      setTotalPage(pageCount)

      if (jobs?.data?.length) {
        return jobs?.data?.map((val, indx) => {
          const location = getLocation(val)
          const isRemoteLocation = getRemoteLocation(val)
          const locationCount = val.locations?.length
            ? val.locations?.length - 1
            : 0
          const createdDuration = getCreatedDuration(val)

          return (
            <a key={`${val}-${indx}`} onClick={() => handleJobClick(val)}>
              <qc-job-listing
                job-title={val.title}
                company-name={val.company?.name}
                company-logo={val?.company?.logo ?? poweredByQureosDark}
                fallback-logo={poweredByQureosDark}
                location={isRemoteLocation || location}
                location-count={locationCount}
                job-posted-duration={createdDuration}
                is-selected={jobId === val._id}
                current-index={indx}
              ></qc-job-listing>
            </a>
          )
        })
      }
    }
  }, [jobs, selectedJob, listLoading, jobId])

  const props = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    itemRender: null,
    customRequest: async req => {
      try {
        if (user) {
          const finalFileName = encodeS3Filename(req.file.name.toString())
          const cloudPath = `job-submission/${jobId}/${user?._id}/${moment
            .utc()
            .format('yyyy-MM-dd_HH-mm-ss')}_${finalFileName}`

          const uploadedUrl = await uploadToGcs(
            cloudPath,
            req.file,
            req.file.type
          )
          if (uploadedUrl) {
            req.onSuccess(
              {
                url: uploadedUrl,
                name: req.file.name
              },
              req.file
            )
          }
        }
      } catch (err) {
        console.log(err, 'caught')
        req.onError(err)
      }
    },
    onChange(info) {
      const { status } = info.file
      if (status === 'done') {
        setParseCV(true)
        setSelectedResume('')
        setCv(info.file.response.url)
        setCvLinks([
          {
            isPrimary: true,
            link: info.file.response.url,
            name: info.file.response.name,
            size: info.file.size,
            uploadedAt: info.file.lastModifiedDate
          }
        ])
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'removed') {
        resetCvSelection()
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
  }

  const trackJobApplication = useCallback(response => {
    const { application, job } = response
    segmentTrackCallback(SegmentEventType.JOB_APPLY, {
      jobTitle: job?.title,
      jobId: job?._id,
      userName:
        application?.apprentice?.firstName +
        ' ' +
        application?.apprentice?.lastName,
      userId: application?.apprentice?._id,
      userEmail: application?.apprentice?.email,
      applicationId: application?._id,
      companyName: job?.company.name,
      companyId: job?.company._id
    })
  }, [])

  const trackPublicJobRedirect = (jobUrl: string) => {
    segmentTrackCallback(SegmentEventType.PUBLIC_JOB_REDIRECT, {
      selectedJob,
      jobUrl,
      userId: user._id,
      userEmail: user.email
    })
    captureEvent({
      _id: selectedJob._id,
      name: UserAnalyticsEventName.PUBLIC_JOB,
      type: UserAnalyticsEventType.REDIRECT
    })
  }

  const updateCvLink = async (cvLink: CV[]) => {
    const resumes = cvLink?.map(item => ({
      link: item.link,
      title: item.name,
      isPrimary: item.isPrimary
    }))
    await updateApprenticesResume(resumes[0])
  }

  const resetCvSelection = () => {
    setParseCV(false)
    setSelectedResume('')
    setCv('')
    setCvLinks([])
    setDraggerKey(nanoid())
  }

  const applyNow = async () => {
    try {
      if (!cvLinks.length && typeof cv === 'undefined') {
        message.error(`You need to upload your CV ${typeof cv}`)
      } else {
        setLoading(true)
        await startApply(jobId, cv, screeningAnswers, parseCV)
          .then(resp => {
            const { data } = resp
            if (data && selectedJob) {
              trackJobApplication({ application: data, job: data.job })
            }
            message.success(`You have now applied to ${selectedJob?.title}`)
            Router.push(
              `/jobs/${jobId}/${
                !!selectedJob?.caseStudy && !selectedJob?.caseStudyNotRequired
                  ? 'apply'
                  : `thank-you?isPublic=${!!selectedJob?.isExternal}`
              }`
            )
          })
          .catch(error => {
            message.error(
              error?.response?.data?.message ?? 'Error Processing apply request'
            )
          })
        if (cvLinks.length && parseCV) updateCvLink(cvLinks)
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message ?? 'Error Processing request'
      )
    } finally {
      setLoading(false)
      resetCvSelection()
    }
  }

  const onApply = async () => {
    if (isLoggedIn()) {
      if (selectedJob?.isExternal) {
        /* only need to check for apply link in case of public jobs  */
        const url = selectedJob?.applyLink || selectedJob?.linkedinLink
        if (url) {
          trackPublicJobRedirect(url)
          window.open(url, '_blank')
          Router.push(
            `/jobs/${selectedJob?._id}/thank-you?isPublic=${!!selectedJob?.isExternal}`
          )
          await triggerSubmissionEmail(jobId)
        } else {
          message.error(`----${Labels('FETCH_JOB_URL_FAIL')}`)
        }
      } else if (!cvLinks.length || selectedJob.screeningQuestions?.length) {
        setModalVisible(true)
      } else {
        await applyNow()
      }
    } else {
      loginRedirect('set', Router.asPath)
      message.error(Labels('SIGNUP_REQUIRED'))
      Router.push({ pathname: '/', query: query })
    }
  }

  const setAnswer = (i: number, answer: string) => {
    setScreeningAnswers(prev =>
      prev.map((sq, index) => (i === index ? { ...sq, answer } : sq))
    )
  }

  const formattedDate = uploadDate => {
    if (uploadDate) {
      return moment(uploadDate).format('DD MMM YYYY')
    }
    return ''
  }

  const selectExistingCv = cvData => {
    if (cvData && !parseCV) {
      const { identifier, isPrimary, link, title, uploadedAt } = cvData
      if (identifier === selectedResume) {
        resetCvSelection()
      } else {
        setCv(link)
        setParseCV(false)
        setSelectedResume(identifier)
        setCvLinks([
          {
            link,
            isPrimary,
            uploadedAt,
            name: title || 'Resume'
          }
        ])
      }
    }
  }

  if (!pageRoute?.asPath) {
    return null
  }

  const customSectionHeight =
    pageRoute?.asPath?.includes('/jobs/search/') && isLoggedIn()
      ? 'max-h-[80vh]'
      : 'max-h-[70vh]'

  if (isEmpty(jobs?.data)) {
    return (
      <div className="flex items-center justify-center min-h-[70vh]">
        {listLoading ? (
          <qc-spinner />
        ) : (
          <EmptyState
            title={Labels('NO_JOBS_FOUND')}
            titleClass="font-bold text-xl text-gray-800"
            descriptionClass="text-gray-500 text-sm xl:px-20 text-center"
            image={<Image src={SourceNoData} width={100} height={100} />}
            description={
              !isEmpty(filters) ? Labels('NO_JOBS_CLEAR_FILTER') : ''
            }
          />
        )}
      </div>
    )
  }
  return (
    <div className="flex flex-row">
      <qc-aside
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
        scroll-upto={scrollHeight}
        aside-class={clsx(
          'border h-full w-[90vw] md:w-96',
          customSectionHeight
        )}
      >
        <div slot="aside-items">{jobsCard}</div>
        <ShouldRender check={totalPage > 0}>
          <div slot="pagination-content" className="overflow-x-clip">
            <Pagination
              totalPage={totalPage}
              currentPage={pageNumber}
              clickEvent={handlePageNumberChange}
              isLoading={listLoading}
            />
          </div>
        </ShouldRender>
      </qc-aside>
      <qc-context
        ref={divRef}
        class={clsx(
          'w-full overflow-y-auto custom-scrollbar p-2 hidden md:block',
          customSectionHeight
        )}
      >
        <div slot="content-items">
          <qc-should-render check={!isEmpty(selectedJob) && !listLoading}>
            <JobDetailView job={selectedJob} onApply={onApply} />
            <div
              slot="fallback"
              className="flex items-center justify-center min-h-[60vh]"
            >
              <qc-should-render check={listLoading}>
                <qc-spinner />
                <div
                  slot="fallback"
                  className="flex items-center justify-center min-h-[60vh]"
                >
                  <EmptyState
                    title={Labels('NO_JOBS_SELECTED')}
                    titleClass="font-bold text-xl text-gray-800"
                    descriptionClass="text-gray-500 text-sm xl:px-20 text-center"
                    image={
                      <Image src={SourceNoData} width={100} height={100} />
                    }
                    description={Labels('SELECT_ANY_JOB')}
                  />
                </div>
              </qc-should-render>
            </div>
          </qc-should-render>
        </div>
      </qc-context>
      <div className="overflow-y-auto custom-scrollbar p-5 block md:hidden">
        <Drawer
          title={selectedJob?.title || ''}
          width="100%"
          className="md:invisible"
          onClose={() => setShowSlider(false)}
          visible={showSlider && !isEmpty(jobId)}
          destroyOnClose
        >
          <div className="w-full">
            <JobDetailView job={selectedJob} onApply={onApply} />
          </div>
        </Drawer>
      </div>
      <Modal
        style={{
          maxWidth: '600px'
        }}
        className="modal-wrapper"
        wrapClassName="custom-scrollbar"
        footer={null}
        title={'Choose Resume'}
        onCancel={() => {
          resetCvSelection()
          setModalVisible(false)
        }}
        visible={modalVisible}
      >
        {!!selectedJob?.screeningQuestions?.length && (
          <>
            <div className="font-semibold mb-4">
              {Labels('ANSWER_FEW_QUESTION')}
            </div>
            {selectedJob?.screeningQuestions?.map((screeningQ, i) => (
              <div key={`${screeningQ.question}-${i}`}>
                <div className="py-2 font-medium">Question {i + 1}</div>
                <div className="py-2">{screeningQ.question}</div>
                <Input
                  className="mt-2 rounded border-[#D7DEE7]"
                  placeholder="Answer"
                  value={screeningAnswers[i]?.answer}
                  onChange={ev => setAnswer(i, ev.target.value)}
                />
              </div>
            ))}
          </>
        )}
        {user?.professionalDetails?.resumes?.slice(0, 1)?.map(res => {
          return (
            <div
              className="w-full flex justify-between items-center my-3 gap-4"
              key={res.identifier}
            >
              <div className="w-10 h-10 min-w-[40px] bg-[#2557ff25] rounded-lg flex justify-center items-center">
                <DocumentTextIcon className="w-6 h-7 fill-current text-qureosPrimary" />
              </div>
              <div className="flex flex-col justify-center w-full">
                <div className="flex justify-between">
                  <p className=" text-gray-700 font-medium">
                    {res.title || 'Resume'}
                  </p>
                </div>
                <span className="text-gray-400 text-sm">
                  Uploaded on {formattedDate(res.uploadedAt)}
                </span>
              </div>
              <input
                type="checkbox"
                checked={res.identifier === selectedResume}
                className="ml-auto text-qureosPrimary h-5 w-5 min-w-[20px] cursor-pointer rounded"
                onClick={() => {
                  selectExistingCv(res)
                }}
              />
            </div>
          )
        })}
        <hr className="border my-5" />
        <p className="font-semibold text-base my-1">
          {Labels('CV_UPLOAD_TITLE')}
        </p>
        <Dragger
          key={draggerKey}
          accept=".pdf"
          beforeUpload={file => {
            if (file.size > maxFileSize) {
              notify({
                message: `Maximum allowed file size is ${getFileSize(maxFileSize)} MB`,
                type: 'error'
              })
              return Upload.LIST_IGNORE
            }
            return true
          }}
          className="w-full relative flex flex-col h-48"
          {...props}
          defaultFileList={
            cv && [
              {
                uid: nanoid(),
                status: 'done',
                fileName: cv,
                url: cv,
                name: cv
              }
            ]
          }
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text p-4">{Labels('CV_DRAG_UPLOAD')}</p>
          <p className="ant-upload-hint">{Labels('CV_FILE_SIZE')}</p>
        </Dragger>
        <small className="block my-3 text-sm text-gray-600">
          {Labels('CV_SUBMIT_MESSAGE')}
        </small>
        <div className="w-full flex justify-end pt-4">
          <div className="w-min flex justify-end gap-4">
            <Button
              onClick={() => {
                if (cvLinks.length && parseCV) resetCvSelection()
                setModalVisible(false)
              }}
              disabled={loading}
              rounded="lg"
              className="font-semibold"
              theme="gray"
            >
              {Labels('CANCEL')}
            </Button>
            <Button
              onClick={applyNow}
              loading={loading}
              rounded="lg"
              className="font-semibold"
              theme="normal"
              disabled={!cvLinks.length || hasPendingQuestions}
            >
              {Labels('APPLY')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PublicJobs
