import { notify } from '@qureos/lib'
import { first } from 'lodash'
import apiClient from 'src/services/base'

const successStatusCodes = [200, 201]

const useGcs = () => {
  const uploadToGcs = async (path, file, type) => {
    try {
      if (!file) {
        throw new Error('No file provided for upload')
      }

      const formData = new FormData()
      formData.append('documents', file)
      formData.append('type', 'other')
      formData.append('customPath', path.split('/').slice(0, -1).join('/'))

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }

      const response = await apiClient.post('/documents', formData, config)

      if (!successStatusCodes.includes(response.status)) {
        throw new Error(`Failed to upload file: ${response.statusText}`)
      }
      return (first(response.data) as any)?.path
    } catch (error) {
      notify({ message: error.message, type: 'error' })
      throw error
    }
  }

  const deleteFromGcs = async path => {
    try {
      if (!path) {
        throw new Error('No path provided to delete')
      }
      const response = await apiClient.delete(`/storage?path=${path}`)

      if (!successStatusCodes.includes(response.status)) {
        throw new Error(`Failed to delete file: ${response.statusText}`)
      }
      return response
    } catch (error) {
      notify({ message: error.message, type: 'error' }) // Assuming notify is defined elsewhere
      throw error
    }
  }

  return {
    uploadToGcs,
    deleteFromGcs
  }
}

export default useGcs
