import { ExclusionTypeEnum, JobContracRequestDto } from '@qureos/types'
import { without } from 'lodash'

export enum CandidateDetailTab {
  PROFILE = 'Profile',
  NOTES = 'Notes',
  JOBS = 'Jobs',
  SCREENING = 'Screening',
  AI_INTERVIEW = 'AI Interview',
  EMAIL_OUTREACH = 'Email Outreach',
  LINKEDIN_OUTREACH = 'LinkedIn Outreach'
}

export const ALL_CANDIDATE_DETAIL_TABS = without(
  Object.values(CandidateDetailTab),
  CandidateDetailTab.JOBS
)

export const contractTypeOptions = [
  {
    label: 'Full-time',
    value: JobContracRequestDto['Full time']
  },
  {
    label: 'Part-time',
    value: JobContracRequestDto['Part time']
  },
  {
    label: 'Internship',
    value: JobContracRequestDto.Intern
  },
  {
    label: 'Contractual',
    value: JobContracRequestDto.Contract
  },
  {
    label: 'Apprenticeship',
    value: JobContracRequestDto.Apprenticeship
  }
]

export const jobFilters = [
  {
    name: 'departmentId',
    placeholder: 'Department'
  },
  {
    name: 'typeOfWork',
    placeholder: 'Job Type'
  },
  {
    name: 'location',
    placeholder: 'Work Location'
  },
  {
    name: 'company',
    placeholder: 'Company'
  }
]

export const PipelineDrawer = {
  outreachSummary:
    'Unleash the potential of your outreach campaign as Iris crafts personalised messages showcasing the role and company. Approve them, and Iris seamlessly engages and diligently follows up with candidates, championing your role.',
  summary:
    "Streamline your outreach by linking your LinkedIn account, automating the process and captivating candidates. Iris will send emails if LinkedIn isn't connected.",
  outreachFourMessage:
    'This campaign consists of 4 messages: Invitation Email, Follow Up Message, Second Follow Up, and Final Reminder. You can customise the messages generated by Iris.',
  outreachThreeMessage:
    'This campaign consists of 3 messages: Invitation Email, Follow Up Message, and Final Reminder. You can customise the messages generated by Iris.',
  linkedInBenefits:
    'Employers who connect their LinkedIn account experience a 6X increase in response rates.'
}

export const DrawerDefaultValues = {
  EMAIL_INVITE: ``,
  EMAIL_FOLLOWUP: ``,
  SECOND_FOLLOWUP: ``,
  EMAIL_REMINDER: ``,

  CONNECTION_REQUEST: ``,
  LINKEDIN_FIRST_MESSAGE: ``,
  LINKEDIN_FOLLOWUP: ``,
  LINKEDIN_REMINDER: ``
}

export const drawerSelectValue = {
  defaultValues: {
    EMAIL_INVITE: 12,
    EMAIL_FOLLOWUP: 12,
    SECOND_FOLLOWUP: 12,
    EMAIL_REMINDER: 12,
    CONNECTION_REQUEST: 12,
    LINKEDIN_FIRST_MESSAGE: 12,
    LINKEDIN_FOLLOWUP: 12,
    LINKEDIN_REMINDER: 12
  },
  defaultOptions: [
    { label: '12 Hour', value: 12 },
    { label: '24 Hour', value: 24 },
    { label: '36 Hour', value: 36 },
    { label: '48 Hour', value: 48 }
  ]
}

export const initialGeneratorLoader = {
  EMAIL_INVITE: false,
  EMAIL_FOLLOWUP: false,
  SECOND_FOLLOWUP: false,
  EMAIL_REMINDER: false,
  CONNECTION_REQUEST: false,
  LINKEDIN_FIRST_MESSAGE: false,
  LINKEDIN_FOLLOWUP: false,
  LINKEDIN_REMINDER: false
}

export const outreachStatus = {
  EMAIL_INVITE: 'connectionRequest',
  EMAIL_FOLLOWUP: 'firstMessage',
  SECOND_FOLLOWUP: 'followUp',
  EMAIL_REMINDER: 'reminder',
  CONNECTION_REQUEST: 'connectionRequest',
  LINKEDIN_FIRST_MESSAGE: 'firstMessage',
  LINKEDIN_FOLLOWUP: 'followUp',
  LINKEDIN_REMINDER: 'reminder'
}

export const outreachStatusMap = {
  email: {
    connectionRequest: 'EMAIL_INVITE',
    firstMessage: 'EMAIL_FOLLOWUP',
    followUp: 'SECOND_FOLLOWUP',
    reminder: 'EMAIL_REMINDER'
  },
  linkedIn: {
    connectionRequest: 'CONNECTION_REQUEST',
    firstMessage: 'LINKEDIN_FIRST_MESSAGE',
    followUp: 'LINKEDIN_FOLLOWUP',
    reminder: 'LINKEDIN_REMINDER'
  }
}

export enum ConnectionStatus {
  SIGN_IN = 'Signin',
  EMAIL = 'Email',
  CONNECTED = 'Connected',
  PENDING = 'Pending',
  RECONNECT = 'Reconnect'
}

export const outreachKeyArray = {
  emailKeyArray: [
    'EMAIL_INVITE',
    'EMAIL_FOLLOWUP',
    'SECOND_FOLLOWUP',
    'EMAIL_REMINDER'
  ],
  linkedInKeysArray: [
    'CONNECTION_REQUEST',
    'LINKEDIN_FIRST_MESSAGE',
    'LINKEDIN_FOLLOWUP',
    'LINKEDIN_REMINDER'
  ]
}

export const outreachTitles = {
  EMAIL_INVITE: 'Invite Email',
  EMAIL_FOLLOWUP: 'Follow Up Email',
  SECOND_FOLLOWUP: 'Second Follow Up Email',
  EMAIL_REMINDER: 'Final Reminder Email',
  CONNECTION_REQUEST: 'Connection Request',
  LINKEDIN_FIRST_MESSAGE: 'First Message',
  LINKEDIN_FOLLOWUP: 'Follow Up',
  LINKEDIN_REMINDER: 'Reminder'
}

export const ImagePlaceHolder =
  'https://static.licdn.com/sc/h/244xhbkr7g40x6bsu4gi6q4ry'

export const rejectReasonsList = [
  {
    label: 'Candidate doesn’t fit my criteria',
    value: ExclusionTypeEnum.NOT_A_FIT
  },
  {
    label: 'Not interested in this candidate',
    value: ExclusionTypeEnum.NOT_INTERESTED
  },
  {
    label: 'Candidate hasn’t responded yet',
    value: ExclusionTypeEnum.NO_RESPONSE
  },
  {
    label: 'Candidate is not relevant for the job',
    value: ExclusionTypeEnum.NOT_RELEVANT
  },
  {
    label: 'Candidate is not interested',
    value: ExclusionTypeEnum.CANDIDATE_NOT_INTERESTED
  },
  { label: 'Other', value: ExclusionTypeEnum.OTHER }
]

export const perPageJobsCount = 9
