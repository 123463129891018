import { getUserCompanyDetails } from 'src/services/company'
import { formatErrorObject } from '../static-helpers/formatError'
import { notify } from '@qureos/lib'

export function fetchMetaScope(
  companyId: string | string[],
  setCompanyMetaData: ({ title, description }) => void
) {
  const fetchData = async () => {
    try {
      if (Array.isArray(companyId)) {
        setCompanyMetaData({
          title: 'Discover Employment Opportunities from Trusted Companies',
          description:
            'Unlock a wealth of employment opportunities from reputable companies. Explore a wide range of job listings, spanning different sectors, and find the perfect fit for your skills and career goals.'
        })
      } else if (companyId) {
        const company = await getUserCompanyDetails(companyId)
        setCompanyMetaData({
          title: company?.name,
          description: company?.seo
        })
      }
    } catch (err) {
      const message = formatErrorObject(err)
      notify({
        message: `There was an error fetching company: ${message}`,
        type: 'error'
      })
    }
  }

  fetchData().catch(err => {
    const message = formatErrorObject(err)
    notify({
      message: `There was an error fetching company: ${message}`,
      type: 'error'
    })
  })
}
